import { Card, CardHeader, CardBody, Heading, Text } from "@chakra-ui/react";

import { EmployerProfileInfo } from "../../../../../shared/employerModels";

interface AthleteAboutCardProps {
  profileData: EmployerProfileInfo;
  editMode?: boolean;
}

const EmployerAboutCard: React.FC<AthleteAboutCardProps> = ({ profileData }) => {

  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>About</Heading>
      </CardHeader>
      <CardBody paddingTop={2} paddingBottom={4}>
        <Text>{profileData.aboutUs || "Something's coming!"}</Text>
      </CardBody>
    </Card>
  );
};

export default EmployerAboutCard;