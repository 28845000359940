import { KeyboardEvent, ChangeEvent, useEffect, useRef, useState } from "react";

import { ArrowUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  AiConversation,
  AiConversationMessage,
  AiConversationResponse,
  AiConversationUserAnswerRequest,
  UserProfileInfo
} from "@shared/models";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SaveHighlightModal } from "./SaveHighlightsModal";
import { AutoResizeTextarea } from "../../components/AutoresizingTextArea";
import { AppConfig } from "../../services/app-config-service";

const roleNames = {
  user: "You",
  assistant: "Coach",
  system: "System"
};

const getProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile`);
  return data as UserProfileInfo;
};

const getConversation = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/conversation`);

  return data as AiConversationResponse;
};

const updateConversation = async (req: { route: string, data: { [key: string]: string[] | number | AiConversationUserAnswerRequest } }) => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/conversation/${req.route}`, req.data);
  return response.data as AiConversationResponse;
}

const replyConversation = async (requestData: AiConversationUserAnswerRequest) => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/conversation/reply`, requestData);
  return response.data as AiConversation;
}

const Magic = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileIsReady, setProfileIsReady] = useState(false);
  const [isStartingConvo, setIsStartingConvo] = useState(false);
  const [isLoadingReply, setIsLoadingReply] = useState(false);
  const [conversation, setConversation] = useState<AiConversation>();
  const [userReply, setUserReply] = useState("");
  const replyBoxRef = useRef<HTMLDivElement>(null);
  const replyInputRef = useRef<HTMLTextAreaElement>(null);
  const [replyBoxHeight, setReplyBoxHeight] = useState<number>(0);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data
    // isLoading,
  } = useQuery({
    queryKey: ["conversation"],
    queryFn: getConversation,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const {
    data: profile
  } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { mutate: saveConversation, isPending } = useMutation({
    mutationFn: updateConversation,
    onSuccess: (data) => {
      onClose();
      setConversation(data.lastConversation);
      setUserReply("");
      setIsLoadingReply(false);
      setIsStartingConvo(false);

      setTimeout(() => {
        replyInputRef.current?.focus();
        window.scrollTo({
          top: document.documentElement.scrollHeight + 100,
          behavior: "smooth"
        });
      }, 100);
    },
    onError() {
      onClose();
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const { mutate: userReplyConversation } = useMutation({
    mutationFn: replyConversation,
    onSuccess: (data) => {
      setUserReply("");

      setConversation(data);
      setIsLoadingReply(false);

      setTimeout(() => {
        replyInputRef.current?.focus();
        window.scrollTo({
          top: document.documentElement.scrollHeight + 100,
          behavior: "smooth"
        });
      }, 100);
    },
    onError(error) {
      console.error({ error })
      setIsLoadingReply(false);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const shouldPromptForFeedback = conversation?.currentStep === 6 && !conversation?.rating;

  useEffect(() => {
    if (profile?.displayName !== "" && profile?.primarySport !== "" && profile?.educationLevel !== "") {
      setProfileIsReady(true);
    }

    if (!data) return;
    setIsLoadingReply(false);
    setConversation(data.lastConversation);
    setIsLoading(false);
    setTimeout(() => {
      replyInputRef.current?.focus();
      window.scrollTo({
        top: document.documentElement.scrollHeight + 100,
        behavior: "smooth"
      });
    }, 100);
  }, [data, profile]);

  useEffect(() => {
    if (isLoading) return;

    if (replyBoxRef.current) {
      setReplyBoxHeight(replyBoxRef.current.offsetHeight);
    }
  }, [isLoading, userReply]);

  function handleStartConversation() {
    handleStartConversationApiCall();
  }

  async function handleStartConversationApiCall() {
    setIsLoading(true);
    setIsStartingConvo(true);

    saveConversation({ route: 'start', data: {} });
  }

  const handleEndConversation = async () => {
    setIsLoadingReply(true);
    saveConversation({ route: 'end', data: {} });
  };

  const handleGiveFeedback = async (rating: number) => {
    setIsLoadingReply(true);

    saveConversation({ route: 'rate', data: { rating } });
  };

  const handleSendResponse = async (contentForModel?: string, contentToDisplay?: string, gotoStep?: number) => {
    const reply = contentForModel || userReply;

    if (reply.trim() === "") return;

    // let's add the user response to the conversation for visual clarity first
    const tempUserMessage: AiConversationMessage = {
      id: 0,
      content: reply,
      contentToDisplay: contentToDisplay || "",
      role: "user",
      createdDate: new Date()
    };

    setConversation((prev: AiConversation | undefined) => {
      if (!prev) return;
      return {
        ...conversation,
        messages: [...prev.messages, tempUserMessage]
      } as AiConversation;
    });

    setTimeout(() => {
      replyInputRef.current?.focus();
      window.scrollTo({
        top: document.documentElement.scrollHeight + 100,
        behavior: "smooth"
      });
    }, 100);

    setIsLoadingReply(true);
    const requestData: AiConversationUserAnswerRequest = {
      content: reply,
      contentToDisplay: contentToDisplay || "",
      gotoStep
    };

    setUserReply("");
    userReplyConversation(requestData);

  };

  if (isLoading) {
    return (
      <Center h="100%">
        <VStack>
          {isStartingConvo && <Text pb={4}>Give us a few seconds to get things started...</Text>}
          <Spinner size={"lg"} />
        </VStack>
      </Center>
    );
  }

  if (!conversation?.messages) {
    return (
      <Container maxWidth={"80ch"} p={[1]}>
        <Heading fontSize="4xl" fontWeight="bold" pt={3}>
          The results are in
        </Heading>
        <VStack gap={5} align={"flex-start"} pt={3}>
          <Text size="md" justifyContent={"left"}>
            As a female athlete, you're a game changer. This isn't flattery or fantasy, it's data and reality. 94% of
            C-suite females played competitive sports and over half at a collegiate level (EY). The skills forged on the
            field, the resilience honed in the locker room, and the unwavering will to win are the bedrock of great
            leaders. The final buzzer doesn't actually mark the end. It marks the beginning of a new game. A game with
            different rules, a different playing field, and an opportunity for even bigger wins.
          </Text>
          {profileIsReady ? (
            <Button variant="cta" onClick={handleStartConversation} hideFrom={"md"}>
              Test the AI Coach
            </Button>
          ) : (
            <Link href="/profile" hideFrom={"md"}>
              <Button variant="secondary">Complete your profile</Button>
            </Link>
          )}
          <Text size="md">
            This is what we do. We build tools and connections so you can stay in the zone - competing at your best with
            the best - while we keep track of what you'll need when it's time to launch your next career.{" "}
          </Text>
          <Text size="md" whiteSpace={"pre-wrap"}>
            Check out our first resource - give it a couple reps. With a few practical questions about what you do day
            by day, game by game, or in a clutch moment, we can help you see and articulate the professional skills that
            companies and HR firms are looking for.
          </Text>
          <UnorderedList pl={2}>
            <ListItem>Grit? Got it. </ListItem>
            <ListItem>Adaptability? No game follows the plan perfectly. </ListItem>
            <ListItem>
              Leadership? No classroom or internship teaches leadership like giving your all for your teammates.{" "}
            </ListItem>
          </UnorderedList>
          {profileIsReady ? (
            <>
              <Text size="md">Check it out now.</Text>
              <Button variant="cta" onClick={handleStartConversation}>
                Test the AI Coach
              </Button>
            </>
          ) : (
            <>
              <Text size="md">
                We use your profile information to customize your experience. Complete your profile to get started.
              </Text>
              <Link href="/profile">
                <Button variant="secondary">Complete your profile</Button>
              </Link>
            </>
          )}
        </VStack>
      </Container>
    );
  }

  function handleUserInput(event: ChangeEvent<HTMLTextAreaElement>): void {
    setUserReply(event.target.value);
  }

  function handleOnKeyDown(event: KeyboardEvent<HTMLTextAreaElement>): void {
    if (event.key === "Enter" && event.metaKey) {
      event.preventDefault();
      handleSendResponse();
    }
  }

  function cleanContent(message: AiConversationMessage): string {
    return message.contentToDisplay || message.content;
  }

  function renderStep2Questions() {
    return (
      <HStack w="100%" justify={"center"} h="100px">
        <Button
          isDisabled={isLoadingReply}
          variant={"secondary"}
          onClick={() =>
            sendCannedReply(
              `Can you ask me one or two more questions and then see if you've found anything? I feel like we can dig a bit deeper. When you feel like you have enough information to help them put a bullet point on their resume, say - "I think I can help you add to your resume! Are you ready, or would you like me to keep asking questions?"`,
              "Not yet, let's chat some more",
              1
            )
          }
        >
          Not yet, let's chat some more
        </Button>
        <Button
          isDisabled={isLoadingReply}
          variant={"cta"}
          onClick={() =>
            sendCannedReply(
              `That would be awesome! Please use my answers to write three different statements that could be used as resume bullet points to help me communicate professional skills that relate to what I described in my athletic experience. Write them without specifically referencing my sport and consider the skills you find to be most valuable. Write them in the resume bullet point format as a JSON object.`,
              "Yes!",
              3
            )
          }
        >
          Yes!
        </Button>
      </HStack>
    );
  }

  function renderStep3Questions() {
    return (
      <VStack w="100%" justify={"center"} h="100px" gap="1">
        <HStack w="100%" justify={"center"} h="90px">
          <Button isDisabled={isLoadingReply} variant={"secondary"} onClick={() => handleEndConversation()}>
            No thanks, I'm good
          </Button>
          <Button
            isDisabled={isLoadingReply}
            variant={"cta"}
            onClick={() =>
              sendCannedReply(
                `Can you help me understand how I demonstrated those things based on what you know?`,
                "Yes please",
                5
              )
            }
          >
            Yes please
          </Button>
        </HStack>
      </VStack>
    );
  }

  function renderSaveStep() {
    return (
      <VStack w="100%" justify={"center"} h="100px" gap="1">
        <Text fontSize="1.1rem" mb={2}>
          Would you like to save these highlights?
        </Text>
        <HStack w="100%" justify={"center"} h="90px">
          <Button
            isDisabled={isLoadingReply}
            variant={"secondary"}
            onClick={() => {
              saveConversation({ route: 'highlights', data: { highlights: [] } });
              // TODO: Make sure this pushes the convo to step 6
            }}
          >
            No thanks
          </Button>
          <Button isDisabled={isLoadingReply} variant={"cta"} onClick={onOpen}>
            Yes please
          </Button>
        </HStack>
      </VStack>
    );
  }

  function renderPromptForFeedback() {
    return (
      <VStack w="100%" justify={"center"} my={4}>
        <Text fontSize="1.1rem" mb={2}>
          Feedback time! How'd we do?
        </Text>
        <Flex w="100%" justify={"center"} flexDirection={["column", "row"]} gap={[3, 2]}>
          <Button onClick={() => handleGiveFeedback(5)}>Love it! 😍</Button>
          <Button onClick={() => handleGiveFeedback(4)}>Like it 👍</Button>
          <Button onClick={() => handleGiveFeedback(3)}>Eh. 😐</Button>
          <Button onClick={() => handleGiveFeedback(2)}>Awful 😠</Button>
          <Button onClick={() => handleGiveFeedback(1)}>Hilarious 🤣</Button>
        </Flex>
      </VStack>
    );
  }

  function getRatingValue(rating: number) {
    switch (rating) {
      case 1:
        return "Hilarious 🤣";
      case 2:
        return "Awful 😠";
      case 3:
        return "Eh. 😐";
      case 4:
        return "Like it 👍";
      case 5:
        return "Love it! 😍";
      default:
        return "Unknown";
    }
  }

  function sendCannedReply(contentForModel: string, contentToDisplay: string, gotoStep?: number) {
    handleSendResponse(contentForModel, contentToDisplay, gotoStep);
  }

  interface BulletedHighlightBody {
    resumeBulletPoints: string[];
  }

  function renderMessageContent(message: AiConversationMessage) {
    if (message.tag === "bulleted-highlights") {
      let list: BulletedHighlightBody | undefined;
      let formatResponse = true;

      try {
        list = JSON.parse(message.content) as BulletedHighlightBody;
      } catch (e) {
        formatResponse = false;
      }

      if (!list || list?.resumeBulletPoints.length === 0) {
        formatResponse = false;
      }

      if (formatResponse && list) {
        return (
          <VStack gap={5} alignItems={"left"}>
            <Text>Here you go:</Text>
            <UnorderedList>
              {list.resumeBulletPoints.map((item, index) => (
                <ListItem key={index}>{item}</ListItem>
              ))}
            </UnorderedList>
            <Text>Would you like an explanation?</Text>
          </VStack>
        );
      } else {
        return <Text whiteSpace={"pre-wrap"}>{cleanContent(message)}</Text>;
      }
    } else {
      return <Text whiteSpace={"pre-wrap"}>{cleanContent(message)}</Text>;
    }
  }

  const getHighlightList = () => {
    if (!isOpen) {
      return [];
    }
    try {
      return JSON.parse(conversation?.messages.find((m) => m.tag === "bulleted-highlights")?.content || "")
        ?.resumeBulletPoints;
    } catch (e) {
      console.error("Error parsing highlights", e);
      return [];
    }
  };
  return (
    <Container maxWidth={"80ch"} p={[1]} position={"relative"} overflowY={"hidden"}>
      <SaveHighlightModal
        isOpen={isOpen}
        onClose={onClose}
        highlights={getHighlightList()}
        onSave={(highlights) => saveConversation({ route: 'highlights', data: { highlights } })}
        isSaving={isPending}
      />
      <VStack pt={1} pb={4} opacity={0.7}>
        <Button color="black" onClick={handleStartConversation}>
          Restart the conversation?
        </Button>
      </VStack>
      <VStack gap={4} pb={`${replyBoxHeight - 40}px`}>
        {conversation?.messages.map((message) => {
          const isUser = message.role === "user";
          const roleName = roleNames[message.role];
          const isSystem = message.role === "system" || message.hideMessage;

          return (
            <Box
              key={message.id}
              bg={isUser ? "brand.primary" : "white"}
              boxShadow={isUser ? "2px 2px 15px -7px rgba(0,0,0,0.75)" : "2px 2px 15px -10px rgba(0,0,0,0.75)"}
              color={isUser ? "white" : "gray.800"}
              borderRadius={8}
              p={3}
              maxWidth={"700px"}
              ml={isUser ? "auto" : 0}
              mr={isUser ? [0, 2] : "auto"}
              fontSize={"md"}
            >
              <Text fontWeight={"bold"}>{roleName}</Text>
              {isSystem && <Text color={"gray.500"}>System message, will be hidden </Text>}
              {renderMessageContent(message)}
            </Box>
          );
        })}
        {isLoadingReply && (
          <Center>
            <Spinner size="md" />
          </Center>
        )}

        {shouldPromptForFeedback && renderPromptForFeedback()}
        {conversation.rating && (
          <VStack py="4">
            <Text fontSize="1.1rem">You rated this conversation:</Text>
            <Box border="1px" py={2} px={3} borderColor={"gray.400"} bg="white" borderRadius={8} fontWeight="600">
              {getRatingValue(conversation.rating)}
            </Box>
          </VStack>
        )}

        <HStack
          pt={0}
          justify={"center"}
          w={["90vw", "90vw", "70vw", "70vw", "820px"]}
          position={"fixed"}
          // display={conversation.currentStep === 5 || shouldPromptForFeedback ? "none" : "flex"}
          bottom={2}
          ref={replyBoxRef}
        >
          <InputGroup
            p="1"
            pl={"1.5"}
            border="1px solid"
            borderColor={"gray.300"}
            borderRadius={6}
            bg="white"
            boxShadow="0 0 10px 10px rgba(255, 255, 255, 0.8)"
          >
            {conversation.currentStep < 2 && (
              <>
                <AutoResizeTextarea
                  ref={replyInputRef}
                  placeholder="Type a message"
                  bg={"white"}
                  color="black"
                  minH={"120px"}
                  mr={16}
                  px={2}
                  border="none"
                  fontSize={"md"}
                  value={userReply}
                  onChange={handleUserInput}
                  onKeyDown={handleOnKeyDown}
                  isDisabled={isLoadingReply}
                  _focusVisible={{ border: "none" }}
                />
                <InputRightElement width="2.5rem">
                  <Box m="4">
                    <Button
                      h="2rem"
                      w="2rem"
                      size="sm"
                      variant="cta"
                      onClick={() => handleSendResponse()}
                      disabled={isLoadingReply}
                    >
                      <ArrowUpIcon fontSize="20px" />
                    </Button>
                  </Box>
                </InputRightElement>
              </>
            )}
            {conversation.currentStep === 2 && renderStep2Questions()}
            {conversation.currentStep === 3 && renderStep3Questions()}
            {conversation.currentStep === 5 && renderSaveStep()}
          </InputGroup>
        </HStack>
      </VStack>
    </Container>
  );
};

export default Magic;
