import { Card, Box, Avatar, CardHeader, Heading, CardBody, VStack, HStack, Text } from "@chakra-ui/react";

import { EmployerProfileInfo } from "../../../../../shared/employerModels";

interface ProfileCardProps {
  profileData: EmployerProfileInfo;
  profileImage: { url: string } | null;
  editMode?: boolean;
}

const EmployerProfileCard: React.FC<ProfileCardProps> = ({ profileData, profileImage, editMode = false }) => {
  const secondLineText = () => {
    if (profileData?.industry && profileData?.location) {
      return `${profileData?.industry} · ${profileData?.location}`;
    }
    if (profileData?.industry) {
      return profileData?.industry;
    }
    if (profileData?.location) {
      return profileData?.location;
    }
    return '';
  }

  return (
    <Card size="lg" w="full" overflow={"hidden"}>
      <Box
        bg="blackAlpha.300"
        w="full"
        h={["60px", 120, 120]}
        alignContent={"flex-end"}
        px={6}
      >
        <HStack justifyContent={"space-between"}>
          <Avatar
            size={["xl", "2xl"]}
            src={profileImage?.url}
            bg="brand.primary"
            top={[5, 12]}
            boxShadow='lg'
          />
          {editMode && (
            <></>
            // <VStack pt={[4, 15]} pr={[5, 8]} bg={'red'}>
            //   <UploadButton
            //     label="Picture"
            //     category="profile-picture"
            //     includeImageCropper
            //     onUpload={() => queryClient.invalidateQueries({ queryKey: ['profileImage'], exact: true })}
            //     onDelete={() => queryClient.invalidateQueries({ queryKey: ['profileImage'], exact: true })}
            //   />
            // </VStack>
          )}
        </HStack>
      </Box>
      <CardHeader paddingBottom={1} paddingTop={16}>
        <Heading size='xl'>{profileData?.companyName}</Heading>
      </CardHeader>
      <CardBody paddingTop={0}>
        <VStack gap={1} w="full" alignItems="flex-start">
          <Heading size='md'>{profileData?.tagline}</Heading>
          <HStack w="full">
            <Text size='md' color={"brand.accent"} fontWeight={550}>
              {profileData?.highlight}
            </Text>
            <Text size='md' color={"GrayText"}>
              {secondLineText()}
            </Text>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default EmployerProfileCard;