import { useEffect, useState } from "react";

import {
  Box,
  Divider,
  HStack,
  VStack,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure
} from "@chakra-ui/react";
// import { useUser } from "@clerk/clerk-react";
import { UserRole } from "@shared/models";
import { motion } from "framer-motion";
import { GoSidebarExpand, GoSidebarCollapse } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";

import { MenuLink, SideMenuLinks, TopMenuLinks } from "./navLinks";
import { useAppStore } from "../app-store";
import { HcaLogo } from "../components/HcaLogo";
import { BrowserService } from "../services/browser-service";
import { ThemeColors } from "../styles/theme";

interface NavLinkMobileProps {
  item: MenuLink;
}

const NavLinkMobile = ({ item }: NavLinkMobileProps) => {
  return (
    <Link to={item.path} target={item.target}>
      <Box
        px={3}
        py={3}
        ml={3}
        display={"inline-block"}
        fontSize={"sm"}
        rounded={"md"}
        color={"black"}
        _hover={{
          textDecoration: "none",
          color: "black",
          bg: "gray.200"
        }}
      >
        {item.label}
      </Box>
    </Link>
  );
};

interface SideBarProps {
  menuIsVisible: boolean;
  closeMenu: () => void;
}

export default function SideBar({ menuIsVisible, closeMenu }: SideBarProps) {
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure({ defaultIsOpen: true });
  const [animationComplete, setAnimationComplete] = useState(true);
  const [showText, setShowText] = useState(true);
  const [allowMobileClicks, setAllowMobileClicks] = useState(false);
  const [isHoveringOverSidebar, setIsHoveringOverSidebar] = useState(false);
  const [linksForUser, setLinksForUser] = useState<MenuLink[]>([]);
  const [userInfo] = useAppStore((state) => [state.userInfo]);

  const isCurrentPath = (path: string) => location.pathname === path;

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (userInfo?.role === UserRole.ADMIN) {
      setLinksForUser(SideMenuLinks);
    } else if (userInfo?.role === UserRole.RECRUITER) {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.RECRUITER)));
    } else if (userInfo?.role === UserRole.UNIVERSITY) {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.UNIVERSITY)));
    } else {
      setLinksForUser(SideMenuLinks.filter((link) => link.role.includes(UserRole.ATHLETE)));
    }
  }, [userInfo]);

  useEffect(() => {
    setShowText(isOpen && animationComplete);
  }, [isOpen, animationComplete]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (menuIsVisible) {
      intervalId = setTimeout(() => {
        setAllowMobileClicks(true);
      }, 500);
    } else {
      setAllowMobileClicks(false);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setAllowMobileClicks(false);
      }
    };
  }, [menuIsVisible]);

  function handleOnMouseOver(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setIsHoveringOverSidebar(true);
  }

  function handleOnMouseOut(event: React.MouseEvent<HTMLElement>): void {
    if (BrowserService.isTouchDevice) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setIsHoveringOverSidebar(false);
  }

  return (
    <Box height="100%">
      {/* For mobile view only */}
      {/* TODO: Clean this up to remove duplicate code */}
      <Drawer isOpen={menuIsVisible} placement="left" onClose={() => closeMenu()} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent bg="brand.background" p={0} pt={14}>
          <DrawerCloseButton color="black" _focusVisible={{ boxShadow: "" }} />

          <DrawerBody p={0} pointerEvents={allowMobileClicks ? "all" : "none"}>
            <Box pt={5} color={"white"}>
              <VStack as={"nav"} spacing={1} align={"left"} justify={"flex-end"} px=".5rem">
                {TopMenuLinks.map((link) => (
                  <NavLinkMobile key={link.label} item={link} />
                ))}
              </VStack>
              <VStack align={"left"} pt={3} pl={0} gap={0}>
                <Divider borderColor={"gray.500"} />
                {linksForUser.map((link) => (
                  <Link to={link.path} key={link.path}>
                    <HStack
                      justify={"flex-start"}
                      cursor={"pointer"}
                      py={2.5}
                      pl={4}
                      color={ThemeColors.brand.primary}
                      bgColor={isCurrentPath(link.path) ? "brand.primary" : "inherit"}
                      // _hover={{ bgColor: "purple.500" }}
                      position={"relative"}
                    >
                      <Box
                        pr={2}
                        fontSize={"1.6rem"}
                        position={"relative"}
                        top={"5px"}
                        left={1}
                        color={isCurrentPath(link.path) ? "white" : "brand.primary"}
                      >
                        {link.icon}
                      </Box>
                      <Text fontSize="sm" align={"center"} color={isCurrentPath(link.path) ? "white" : "black"}>
                        {link.label}
                      </Text>
                    </HStack>
                    <Divider borderColor={"gray.500"} />
                  </Link>
                ))}
              </VStack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* For desktop view only */}
      <Box
        hideBelow={"md"}
        position={"relative"}
        bg="brand.background"
        height="100%"
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        <motion.div
          {...getDisclosureProps()}
          hidden={false}
          initial={false}
          onAnimationStart={() => setAnimationComplete(false)}
          onAnimationComplete={() => setAnimationComplete(true)}
          animate={{ width: isOpen ? 220 : 80 }}
          style={{
            backgroundColor: "brand.background",
            height: "100%",
            paddingTop: "20px",
          }}
        >
          <Box pt={2} color={"white"}>
            <HcaLogo boxSize={isOpen ? 20 : 16} ps={4} />

            {BrowserService.isTouchDevice && <Box w="full" h="3.5rem" bg="brand.background" {...getButtonProps()} />}
            <VStack align={"left"} pt={BrowserService.isTouchDevice ? 0 : "2.5rem"} paddingX={3} gap={3} h="100%">
              {/* <Divider borderColor={"gray.500"} /> */}
              {linksForUser.map((link) => (
                <Link to={link.path} key={link.label}>
                  <HStack
                    justify={"flex-start"}
                    cursor={"pointer"}
                    borderRadius={7}
                    pl={".6rem"}
                    w={"full"}
                    bg={currentPath === link.path ? "brand.primary" : "inherit"}
                    _hover={currentPath === link.path ? {} : { bgColor: "brand.backgroundHover" }}
                    gap={3}
                  >
                    <motion.div
                      style={{ position: "relative" }}
                      animate={{
                        left: isOpen ? 3 : 6,
                        top: isOpen ? 3 : 5,
                        fontSize: isOpen ? "1.4rem" : "1.6rem"
                      }}
                      initial={false}
                    >
                      <Box color={currentPath == link.path ? "white" : ThemeColors.brand.primary}>
                        {link.icon}
                      </Box>
                    </motion.div>
                    {showText && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2, ease: "easeOut" }}
                      >
                        <Text fontSize="sm" align={"center"} color={currentPath === link.path ? "white" : "black"}>
                          {link.label}
                        </Text>
                      </motion.div>
                    )}
                  </HStack>
                </Link>
              ))}
            </VStack>
          </Box>
          {BrowserService.isTouchDevice && <Box w="100%" h="100%" bg="brand.background" {...getButtonProps()} />}
        </motion.div>
        <Box position={"absolute"} top={"10px"} right={"5px"} zIndex={1000}>
          {isHoveringOverSidebar && (
            <button {...getButtonProps()}>
              <Box fontSize={"1.5rem"} color="gray.800" onClick={() => closeMenu()}>
                {isOpen ? <GoSidebarExpand /> : <GoSidebarCollapse />}
              </Box>
            </button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
