import { useMemo, useState } from "react";

import {
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Table,
  Tbody,
  Td,
  Tr,
  Button,
  useToast,
  Box
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { HighlightBullet } from "../../../../../shared/models";
import { AutoResizeTextarea } from "../../components/AutoresizingTextArea";

type AiBulletListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (bullets: HighlightBullet[]) => void;
  highlights: HighlightBullet[];
};

function getUserHighlightBullets() {
  return axios.get("/api/profile/highlight-bullets").then((response) => response.data as HighlightBullet[]);
}

function updateUserHighlightBullets(bullets: Partial<HighlightBullet>[]) {
  return axios.post("/api/profile/highlight-bullets", bullets).then((response) => response.data as HighlightBullet);
}

export function AiBulletListModal({ isOpen, onClose, highlights, onSave }: AiBulletListModalProps) {
  const [updatedHighlights, setUpdatedHighlights] = useState<HighlightBullet[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<HighlightBullet[]>(highlights.filter((highlight) => highlight.selected === true));
  const selectedIds = useMemo(() => selectedCheckboxes?.map((item) => item.id), [selectedCheckboxes]);
  const toast = useToast();

  const { data: savedHighlights, refetch } = useQuery({
    queryKey: ["highlight-bullets"],
    queryFn: getUserHighlightBullets
  });

  const { mutate: updateHighlight, isPending } = useMutation({
    mutationFn: updateUserHighlightBullets,
    onSuccess(_data, variables) {
      const items = selectedCheckboxes?.map((item) => {
        const foundItem = variables.find((highlight) => highlight.id === item.id);
        if (foundItem) {
          item.contentToDisplay = foundItem.contentToDisplay || item.contentToDisplay;
        }
        return item;
      });
      if (items) onSave?.(items);
      onClose();
      refetch();
    },
    onError(error) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    }
  });

  const handleCheckboxChange = (value: HighlightBullet) => {
    if (selectedIds?.includes(value.id)) {
      value.selected = false;
      setSelectedCheckboxes(selectedCheckboxes?.filter((item) => item.id !== value.id));
    } else {
      value.selected = true;
      selectedCheckboxes ? setSelectedCheckboxes([...selectedCheckboxes, value]) : setSelectedCheckboxes([value]);
    }
  };

  const handleOnSave = () => {
    if (updatedHighlights.length > 0) {
      updateHighlight(updatedHighlights.map((item) => ({ id: item.id, contentToDisplay: item.contentToDisplay })));
    } else {
      if (selectedCheckboxes) onSave?.(selectedCheckboxes);
      onClose();
    }
  };

  const handleOnBlur = (highlight: HighlightBullet, newContentToDisplay: string) => {
    if (highlight.contentToDisplay === newContentToDisplay) {
      return;
    }

    setUpdatedHighlights([
      ...updatedHighlights.filter((item) => item.id !== highlight.id),
      { ...highlight, contentToDisplay: newContentToDisplay }
    ]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay onClick={onClose} />
      <ModalContent>
        <ModalHeader>
          Highlight Reel
          <Text fontSize="sm" color="gray.500">
            Refine your highlights & choose your top 3
          </Text>
        </ModalHeader>
        <ModalBody>
          <Box maxHeight={["300px", "400px"]} overflowY="auto">
            <Table>
              <Tbody>
                {savedHighlights?.map?.((highlight) => (
                  <Tr key={"highlight-" + highlight.id}>
                    <Td width="30px" px={0}>
                      <Checkbox
                        key={"highlight-" + highlight.id}
                        isChecked={selectedIds?.includes(highlight.id)}
                        onChange={() => handleCheckboxChange(highlight)}
                        disabled={(selectedCheckboxes?.length === 3 && !selectedIds?.includes(highlight.id)) || isPending}
                        colorScheme="brand"
                        defaultChecked={highlight.selected}
                      />
                    </Td>
                    <Td pl={0}>
                      <AutoResizeTextarea
                        defaultValue={highlight.contentToDisplay}
                        fontSize={"sm"}
                        onBlur={(e) => handleOnBlur(highlight, e.target.value)}
                        isDisabled={isPending}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={onClose} mr={2}>
            Cancel
          </Button>
          <Button colorScheme="purple" onClick={handleOnSave} isLoading={isPending}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
