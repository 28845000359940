import { Center, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { EmployerProfileInfo } from "../../../../../shared/employerModels";
import EmployerAboutCard from "../../components/cards/EmployerAboutCard";
import EmployerProfileCard from "../../components/cards/EmployerProfileCard";
import { Page } from "../../components/Page";
import { AppConfig } from "../../services/app-config-service";

const profileImage = null;

const getEmployerProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/employer/profile`);
  return data as EmployerProfileInfo;
};

// const getProfileImage = async () => {
//   const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/avatar`);
//   return data;
// };

export function EmployerProfilePage() {
  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profileData"],
    queryFn: getEmployerProfile,
    refetchOnWindowFocus: "always"
  });

  // const { data: profileImage } = useQuery({
  //   queryKey: ["profileImage"],
  //   queryFn: getProfileImage,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false
  // });

  if (isProfileLoading || !profileData) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <Page>
      <EmployerProfileCard profileData={profileData} profileImage={profileImage} />

      <EmployerAboutCard profileData={profileData} />
    </Page>
  );
}