import { memo } from "react";

import {
  Button,
  ButtonGroup,
  IconButton,
  ButtonProps,
  ButtonGroupProps,
  IconButtonProps,
  useDisclosure
} from "@chakra-ui/react";
import { FaTrashCan } from "react-icons/fa6";

import { ConfirmModal } from "./ConfirmModal";
import { theme } from "../styles/theme";

interface DownloadFileWithDeleteProps {
  buttonLabel: string;
  disabled?: boolean;
  onCancelDelete?: () => void;
  onConfirmDelete?: () => void;
  onDownloadClicked?: () => void;

  // Pass-through props
  buttonGroupProps?: ButtonGroupProps;
  buttonProps?: ButtonProps;
  iconButtonProps?: IconButtonProps;
}

export const DownloadFileWithDelete = memo(
  ({
    buttonLabel,
    disabled,
    onCancelDelete,
    onConfirmDelete,
    onDownloadClicked,

    buttonGroupProps,
    buttonProps,
    iconButtonProps
  }: DownloadFileWithDeleteProps) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
      <>
        <ButtonGroup isAttached variant="secondary" marginY="auto" {...buttonGroupProps} isDisabled={disabled}>
          <Button {...buttonProps} onClick={onDownloadClicked}>
            {buttonLabel}
          </Button>
          <IconButton
            aria-label={buttonLabel}
            icon={<FaTrashCan />}
            {...iconButtonProps}
            style={{
              transition: "background-color 0.5s ease, color 0.5s ease"
            }}
            css={{
              ":hover": {
                backgroundColor: theme.components.Button.variants.danger.bg,
                color: theme.components.Button.variants.danger.color
              }
            }}
            onClick={(e) => {
              onOpen();
              iconButtonProps?.onClick?.(e);
            }}
          />
        </ButtonGroup>
        <ConfirmModal isOpen={isOpen} onClose={onClose} onConfirm={onConfirmDelete} onCancel={onCancelDelete} />
      </>
    );
  }
);
