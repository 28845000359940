import axios from "axios";

import { AppConfig } from "./app-config-service";
import { University } from "../../../../shared/models";

export const getUniversityName = async (universityId: number): Promise<University> => {
  const response = await axios.get(`${AppConfig.apiUrl}/api/universities`, { params: { universityId: universityId } });
  return response.data;
};

export const getAllUniversities = async (): Promise<University[]> => {
  const response = await axios.get(`${AppConfig.apiUrl}/api/universities-all`);
  return response.data.sort((a: University, b: University) =>
    a.universityName.localeCompare(b.universityName)
  );
};

export const updatePrimaryUniversity = async (universityId: number): Promise<void> => {
  const response = await axios.post(`${AppConfig.apiUrl}/api/update-admin-primary-university`, { universityId: universityId }, {
    headers: {
      "Content-Type": "application/json"
    }
  });
  return response.data;
};
