import { useRef, useState } from "react";

import { Button, Flex, Modal, ModalContent, ModalFooter, ModalOverlay, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CircleStencil, FixedCropper, FixedCropperRef, ImageRestriction } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

import { DownloadFileWithDelete } from "./DownloadFileWithDelete";
import { UploadButtonWithProgress } from "./UploadButtonWithProgress";
import { FileUploadMetadata, UploadCategory } from "../../../../shared/models";
import { UploadFileArgs, downloadFile, uploadFile } from "../services/file-service";
type UploadButtonProps = {
  label: string;
  category: UploadCategory;
  onFileUploaded?: (metadata: FileUploadMetadata) => void;
  includeImageCropper?: boolean;
  onUpload?: () => void;
  onDelete?: () => void;
};

const getFile = async (category: string) => {
  const { data } = await axios.get(`/api/file/${category}`);
  return data as FileUploadMetadata | undefined;
};

const postFileData = async (metadata: FileUploadMetadata, category: string) =>
  await axios.post(`/api/file/${category}`, { ...metadata });

const deleteFileData = async (category: string) =>
  await axios.delete(`/api/file/${category}`);

export const UploadButton = ({
  label,
  category,
  onFileUploaded,
  includeImageCropper,
  onUpload,
  onDelete
}: UploadButtonProps) => {
  const toast = useToast();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileBlob, setUploadedFileBlob] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cropperRef = useRef<FixedCropperRef | null>(null);

  const closeImageCropper = () => {
    onClose();
    setUploading(false);
    setUploadedFile(null);
    setUploadedFileBlob("");
  };

  const {
    data: file,
    isLoading: isLoadingFile,
    refetch
  } = useQuery({
    queryKey: ["file", category],
    queryFn: () => getFile(category),
    refetchOnWindowFocus: false
  });

  const uploadAndSave = async (args: UploadFileArgs) => {
    const data = await uploadFile(args);
    if (!data) return;
    await postFileData(data, category);
    return data
  };

  const { mutate: fileUploadMutation } = useMutation({
    mutationFn: uploadAndSave,
    onSuccess: (data) => {
      refetch();
      onUpload?.();
      setProgress(0);
      setUploading(false);
      onFileUploaded?.(data as FileUploadMetadata);
    },
    onError: (error) => {
      console.error("Error uploading file:", error);
      setProgress(0);
      setUploading(false);
      toast({
        title: "Error!",
        description: "There was an issue uploading the file",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const { mutate: deleteUploadMutation } = useMutation({
    mutationFn: () => deleteFileData(category),
    onSuccess: () => {
      refetch();
      onDelete?.();
    },
    onError: (error) => {
      console.error("Error deleting file:", error);
      setProgress(0);
      toast({
        title: "Error!",
        description: "There was an issue deleting the file",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const handleFileUploaded = (f: File) => {
    if (uploading) return;
    setUploading(true);

    if (includeImageCropper) {
      const blob = new Blob([f], { type: f.type });
      setUploadedFile(f);
      setUploadedFileBlob(window.URL.createObjectURL(blob));
      onOpen();
      return;
    }

    fileUploadMutation({
      file: f,
      onProgress: (percentage: number) => setProgress(percentage)
    });
  };

  const onCrop = () => {
    if (!cropperRef?.current) return alert("Something went wrong");

    cropperRef.current.getCanvas()?.toBlob((blob) => {
      if (!blob) return;
      const file = new File([blob], uploadedFile?.name ?? "cropped.png", { type: "image/png" });
      fileUploadMutation({
        file,
        onProgress: (percentage: number) => setProgress(percentage)
      });
      onClose();
    }, "image/png");
  };

  const handleDownloadClicked = async () => {
    if (!file) return;
    setIsDownloading(true);
    await downloadFile({ category, originalFilename: file.originalFilename });
    setIsDownloading(false);
  };

  const handleDeleteClicked = async () => {
    if (!file) return;
    deleteUploadMutation();
  };

  if (file && !uploading) {
    return (
      <DownloadFileWithDelete
        buttonLabel={`Save ${label}`}
        onDownloadClicked={handleDownloadClicked}
        disabled={isDownloading}
        onConfirmDelete={handleDeleteClicked}
      />
    );
  }

  return (
    <>
      <UploadButtonWithProgress
        buttonLabel={`Upload ${label}`}
        onFileUploaded={handleFileUploaded}
        progress={progress}
        disabled={uploading || isLoadingFile}
      />

      <Modal isOpen={isOpen} onClose={closeImageCropper} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent w="80%">
          <FixedCropper
            src={uploadedFileBlob}
            ref={cropperRef}
            stencilComponent={CircleStencil}
            stencilSize={{
              width: 300,
              height: 300
            }}
            stencilProps={{
              handlers: true,
              resizable: true,
              aspectRatio: 1
            }}
            style={{
              minHeight: "350px"
            }}
            imageRestriction={ImageRestriction.stencil}
          />
          <ModalFooter>
            <Flex width={"100%"} flexWrap="wrap" gap={2} justifyContent="space-evenly">
              <Button mr={3} onClick={closeImageCropper} size="lg">
                Close
              </Button>
              <Button colorScheme="purple" size="lg" onClick={onCrop}>
                Crop
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
