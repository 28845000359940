import { Icon, IconProps } from "@chakra-ui/react";

type Props = {
  color?: string;
} & IconProps;

export const HcaLogo = ({ color = "brand.primary", ...props }: Props) => (
  <Icon viewBox="0 -40 200 200" color={color} {...props}>
    <path fill="currentColor" d="M36.059 104.507c-.011-2.933.628-5.816 1.732-8.524 4.68-10.705 10.383-20.966 14.907-31.744 2.309-5.776.687-12.474-4.448-16.23-2.806-2.118-6.39-3.3-9.907-2.926-3.44.347-6.695 2.134-10.163 3.01C19.076 50.526 9.241 46.811 4 39.048-3.594 28.237-.004 12.74 11.633 6.433c12.38-7.1 28.878-.303 32.663 13.465.79 2.415 1.44 4.92 2.565 7.01a13.585 13.585 0 0 0 4.835 5.198c5.006 3.235 12.108 3.32 16.293-1.32 3.515-3.87 5.294-8.966 7.748-13.503 2.317-4.56 4.556-9.73 8.683-12.922C97.886-5.954 118.69 3.25 122.337 19.24c1.352 5.211.602 10.642-1.615 15.526-1.552 3.513-3.355 6.89-4.968 10.366-1.474 3.156-3.193 6.41-2.771 9.882 1.308 9.137 13.526 12.911 18.797 4.633 8.47-15.555 15.472-31.909 23.672-47.613 1.32-2.457 2.846-4.855 4.905-6.746 5.121-4.735 12.886-6.1 19.654-4.604 11.702 2.396 21.039 13.99 19.893 26.016-.352 4.124-2.234 8.01-4.026 11.713-12.78 26.36-25.35 52.828-38.387 79.059-1.219 2.195-2.674 4.254-4.48 5.987-8.718 8.634-22.852 6.856-31.554-.803-5.648-4.971-9.024-12.786-8.303-20.334.331-5.28 4.057-10.03 4.807-15.02.537-3.987-1.663-7.941-5.076-10.126-4.352-2.86-10.118-1.985-13.12 2.341-1.23 1.642-2.148 3.537-3.07 5.372-4.95 9.907-9.756 19.883-14.665 29.813-.956 1.891-1.954 3.8-3.143 5.557-4.217 6.7-12.44 9.719-20.165 8.795-12.18-1.158-22.7-12.147-22.663-24.5v-.047Z" />
  </Icon>
)
