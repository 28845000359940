import { UserInfo } from "@shared/models";
import { create } from "zustand";

interface AppState {
  userInfo?: UserInfo;
  setUserInfo: (val: UserInfo) => void;
}

export const useAppStore = create<AppState>()((set) => ({
  userInfo: undefined,
  setUserInfo: (val) => set({ userInfo: val })
}));
