import axios from "axios";

import { FilterValues } from "./FilterPanel";
import { UserProfileSearchResult } from "../../../../../shared/models";
import { AppConfig } from "../../services/app-config-service";

export const fetchFindAthletesProfiles = async (
  filters: FilterValues & { search?: string; page?: number; pageSize?: number } = {}
): Promise<{ users: UserProfileSearchResult[], totalResults: number, totalResultsPendingLogin: number, currentPage: number, totalPages: number }> => {
  const response = await axios.get(`${AppConfig.apiUrl}/api/search/find-athletes`, {
    params: {
      ...filters,
      state: filters.state?.value && filters.state?.value !== "Any" ? filters.state.value : undefined
    }
  });

  return response.data;
};

export const fetchSearchProfiles = async (
  filters: FilterValues & { search?: string; page?: number; pageSize?: number, hidePendingAthletes?: boolean } = {}
): Promise<{ users: UserProfileSearchResult[], totalResults: number, totalResultsPendingLogin: number, currentPage: number, totalPages: number }> => {
  const response = await axios.get(`${AppConfig.apiUrl}/api/search/university/profiles`, {
    params: {
      ...filters,
      state: filters.state?.value && filters.state?.value !== "Any" ? filters.state.value : undefined,
    }
  });

  return response.data;
};
