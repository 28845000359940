import { useEffect } from 'react';

import { Center, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { RoutePath, UserRole } from '../../../../shared/models';
import Page404 from '../pages/404/404Page';
import { AppConfig } from '../services/app-config-service';

interface UserInfo {
  role: UserRole;
}

const getUserInfo = async (): Promise<UserInfo> => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/user-info`);
  return data;
};

const UserRoleRedirect: React.FC = () => {
  const navigate = useNavigate();
  const { data: userInfo, isLoading, isError } = useQuery<UserInfo>({
    queryKey: ['userInfo'],
    queryFn: getUserInfo,
  });

  useEffect(() => {
    if (userInfo) {
      switch (userInfo.role) {
        case UserRole.RECRUITER:
          navigate(RoutePath.EMPLOYER_DASHBOARD);
          break;
        case UserRole.ATHLETE:
          navigate(RoutePath.ATHLETE_DASHBOARD);
          break;
        case UserRole.UNIVERSITY:
          navigate(RoutePath.UNIVERSITY_DASHBOARD);
          break;
        case UserRole.ADMIN:
          navigate(RoutePath.ADMIN);
          break;
        default:
          navigate(RoutePath.ATHLETE_DASHBOARD);
      }
    }
  }, [userInfo, navigate]);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (isError) {
    return <Page404 />
  }

  return (
    <Center h="100vh">
      <Spinner size="xl" />
    </Center>
  );
};

export default UserRoleRedirect;
