import axios from "axios";

import { AppConfig } from "./app-config-service";
import { UserProfileInfo } from "../../../../shared/models";

export const getProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile`);
  return data as UserProfileInfo;
};

export const getProfileImage = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/avatar`);
  return data;
};

export const getSpecificProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}`);
  return data as UserProfileInfo;
};

export const getSpecificProfileImage = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profile/${userId}/avatar`);
  return data;
};