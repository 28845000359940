import { useEffect, useState } from "react";

import { University } from "../../../../shared/models";
import { getUniversityName } from "../services/hca-api";

export const useUniversity = (universityId: number | null) => {
  const [university, setUniversity] = useState<University>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!universityId) return;
    setLoading(true);
    getUniversityName(universityId)
      .then((data) => {
        setUniversity(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [universityId]);

  return { university, loading, error };
};