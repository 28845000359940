import { Center, Spinner, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';

import { getSpecificProfile, getSpecificProfileImage } from "../../services/athlete-api";
import { AthleteProfile } from "../athlete";

export function SpecificAthleteProfilePage() {
  const { userId } = useParams();
  const { data: profileData, isFetching: isProfileFetching, isError: isProfileError } = useQuery({
    queryKey: ["specificProfileData"],
    queryFn: () => getSpecificProfile(Number(userId)),
    refetchOnWindowFocus: "always",
  });

  const { data: profileImage, isFetching: isProfileImageFetching } = useQuery({
    queryKey: ["specificProfileImage"],
    queryFn: () => getSpecificProfileImage(Number(userId)),
    refetchOnMount: true,
  });

  if (isProfileError) {
    return (
      <Center h="100%">
        <Text color="red.500" fontSize="2xl" fontWeight="bold">User does not exist</Text>
      </Center>
    );
  }

  if (!profileData || isProfileFetching || isProfileImageFetching) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }



  return (
    <AthleteProfile profileData={profileData} profileImage={profileImage} />
  );
}