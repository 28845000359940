import { useRef, useEffect } from "react";

import { Box, Center, Spinner, VStack } from "@chakra-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";

import { University, UserProfileSearchResult } from "../../../../../shared/models";
import AthleteProfilePreviewCard from "../../components/cards/AthleteProfilePreviewCard";

interface ProfileListProps {
  profiles: UserProfileSearchResult[];
  hasNextPage: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage?: () => void;
  universityList?: University[];
}

export const ProfileList: React.FC<ProfileListProps> = ({
  profiles,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  universityList,
  fetchNextPage
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? profiles.length + 1 : profiles.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 160
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= profiles.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage && fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, profiles.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()]);

  useEffect(() => {
    parentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [profiles]);

  const items = rowVirtualizer.getVirtualItems();

  if (isFetching && !isFetchingNextPage) {
    return (
      <Box>
        <Center h="100%">
          <Spinner size={"lg"} />
        </Center>
      </Box>
    );
  }

  return (
    <Box flexGrow="1" flexShrink="1" overflowY="auto" height="0px" ref={parentRef} style={{ overflowAnchor: "none" }}>
      <Box
        style={{
          height: rowVirtualizer.getTotalSize(),
          width: "100%",
          position: "relative"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start ?? 0}px)`
          }}
        >
          <VStack spacing={[2, 4]}>
            {items.map((virtualRow) => {
              const isLoaderRow = virtualRow.index > profiles.length - 1;
              const profile = profiles[virtualRow.index];

              return (
                <div key={virtualRow.key} ref={rowVirtualizer.measureElement} data-index={virtualRow.index} style={{ width: "100%" }}>
                  {(isLoaderRow) ? (
                    (hasNextPage && fetchNextPage) ? (
                      "Loading more..."
                    ) : null
                  ) : (
                    <AthleteProfilePreviewCard key={virtualRow.index} profileData={profile} profileImage={profile.profileImageUrl} universityName={universityList?.find(university => university.id === profile.universityId)?.universityName} />
                  )}
                </div>
              );
            })}
          </VStack>
        </div>
      </Box>
    </Box>
  );
};