import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  // IconButton,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { RoutePath, University, UserProfileInfo, UserRole } from "@shared/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Select as ReactSelect } from "chakra-react-select";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { Navigate } from "react-router-dom";

import { UpdateUserRole } from "./UpdateUserRole";
import { useAppStore } from "../../app-store";
import { AppConfig } from "../../services/app-config-service";
import { getAllUniversities, updatePrimaryUniversity } from "../../services/hca-api";
import { calculateProfileCompletion } from "../../utils";

interface AdminAthleteRowProps {
  profile: AthleteAdminData;
}

interface AthleteAdminData extends UserProfileInfo {
  percentageComplete: number;
}

interface DataSectionProps {
  title: string;
  children: React.ReactNode;
}

function DataSection({ title, children }: DataSectionProps) {
  return (
    <VStack
      marginTop={4}
      p={4}
      justifyContent={"left"}
      alignItems={"flex-start"}
      w="100%"
      maxH={["80vh", "50vh"]}
      overflowY={"auto"}
      bg={"white"}
      borderRadius={4}
      boxShadow={"2px 2px 15px -10px rgba(0,0,0,0.75)"}
    >
      <Heading fontSize={"2xl"} mb={2}>
        {title}
      </Heading>
      <VStack w="100%" gap={[3, 4]}>
        {children}
      </VStack>
    </VStack>
  );
}

function InsightRecord({ title, value }: { title: string; value: string | number }) {
  return (
    <HStack w={"100%"} justify={"space-between"} borderBottomWidth={1} borderBottomColor={"#ccc"} pb={1}>
      <Text>{title}</Text>
      <Text>{value}</Text>
    </HStack>
  );
}

const getProfiles = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profiles`);

  return data as UserProfileInfo[] | undefined;
};

export const AdminPage = () => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [allProfiles, setAllProfiles] = useState<AthleteAdminData[]>([]);
  const [personalUniversity, setPersonalUniversity] = useState<University | null>(null);
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["profiles"],
    queryFn: getProfiles,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const {
    data: universities,
    isLoading: isLoadingUniversities,
  } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
  });

  const { mutate: updateAdminPrimaryUniversity } = useMutation({
    mutationFn: updatePrimaryUniversity,
    onSuccess: () => {
      toast({
        title: "Primary university updated",
        status: "success",
        duration: 3000,
        position: "top",
      });
      queryClient.invalidateQueries({ queryKey: ["universities"] });
    }
  })

  const changePrimaryUniversity = (option: University | null) => {
    if (!option || !option.id) return;
    updateAdminPrimaryUniversity(option.id)
    setPersonalUniversity(option);
  }

  useEffect(() => {
    if (!data) return;

    const fetchUserInfo = () => {
      const athleteAdminProfileData = [] as AthleteAdminData[];
      data.forEach((profile) => {
        const percentageComplete = calculateProfileCompletion(profile, "athlete");
        athleteAdminProfileData.push({ ...profile, percentageComplete });
      });
      athleteAdminProfileData.sort((a, b) => b.percentageComplete - a.percentageComplete);
      setAllProfiles(athleteAdminProfileData);
    };
    fetchUserInfo();
  }, [data]);

  useEffect(() => {
    if (!universities) return;
    const personalUniversity = universities.find((uni) => uni.id === userInfo?.primaryUniversityId);
    setPersonalUniversity(personalUniversity ?? null);
  }, [universities, userInfo]);

  // TODO: We should add page guards at a higher level
  if (userInfo && userInfo.role !== UserRole.ADMIN) {
    return <Navigate to={RoutePath.ROOT} />;
  }

  if (isLoading || isLoadingUniversities) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  const AthleteInsightsBlock = ({ athleteProfiles }: { athleteProfiles: AthleteAdminData[] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const readyToWorkCount = athleteProfiles.filter((athlete) => athlete.readyToWork).length;
    const totalPercentage = allProfiles.reduce((acc, profile) => acc + profile.percentageComplete, 0);
    const averagePercentage = Math.round(totalPercentage / allProfiles.length);
    return (
      <VStack align={"start"} w={"100%"}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          cursor={"pointer"}
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={4}
          pr={[4, 6]}
        >
          <Heading size={"md"}>Athletes</Heading>
          <Text>{athleteProfiles.length}</Text>
        </Button>
        {isOpen && (
          <VStack align={"start"} px={3} pr={[4, 6]} gap={[2, 3]} w={"100%"}>
            <InsightRecord title={"Ready to Work"} value={readyToWorkCount} />
            <InsightRecord title={"Average Profile Complete"} value={`${averagePercentage}%`} />
          </VStack>
        )}
      </VStack>
    );
  };

  const PlatformInsightBlock = ({ title, data }: { title: string; data: { name: string; count: number }[] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const itemsToShow = showAll ? data : data.slice(0, 10);

    return (
      <VStack align={"start"} w={"100%"}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          cursor={"pointer"}
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={4}
          pr={[4, 6]}
        >
          <Heading size={"md"}>{title}</Heading>
          <Text>{data.length}</Text>
        </Button>
        {isOpen && (
          <VStack align={"start"} px={3} gap={[2, 3]} w={"100%"} pr={[4, 6]}>
            {itemsToShow.map((item) => (
              <InsightRecord key={item.name} title={item.name} value={item.count} />
            ))}
            {data.length > 10 && (
              <HStack w={"100%"} justify={showAll ? "space-between" : "flex-end"} pb={1}>
                {showAll && (
                  <Button onClick={() => setIsOpen(!isOpen)} variant={"link"} color={"brand.primary"}>
                    Collapse
                  </Button>
                )}
                <Button
                  onClick={() => setShowAll(!showAll)}
                  variant={"link"}
                  color={"brand.primary"}
                  justifyContent={"flex-end"}
                >
                  {showAll ? "Show Less" : "Show All"}
                </Button>
              </HStack>
            )}
          </VStack>
        )}
      </VStack>
    );
  };

  const PlatformInsights = ({ allProfiles: athleteProfiles }: { allProfiles: AthleteAdminData[] }) => {
    const universityList: { name: string; id: number; count: number }[] = [];
    const sportList: { name: string; count: number }[] = [];
    const majorList: { name: string; count: number }[] = [];

    athleteProfiles.forEach((athlete) => {
      if (athlete?.universityId && universities) {
        const index = universityList.findIndex((u) => u.id === athlete.universityId);
        if (index === -1) {
          const universityIndex = universities?.findIndex((u) => u.id === athlete.universityId);
          universityList.push({
            name: universities?.[universityIndex]?.universityName ?? "",
            id: athlete.universityId,
            count: 1,
          });
        } else {
          universityList[index].count++;
        }
      }

      if (athlete.primarySport) {
        const index = sportList.findIndex((s) => s.name === athlete.primarySport);
        if (index === -1) {
          sportList.push({ name: athlete.primarySport, count: 1 });
        } else {
          sportList[index].count++;
        }
      }

      if (athlete.major) {
        const index = majorList.findIndex((m) => m.name === athlete.major);
        if (index === -1) {
          majorList.push({ name: athlete.major, count: 1 });
        } else {
          majorList[index].count++;
        }
      }
    });

    universityList.sort((a, b) => b.count - a.count);
    sportList.sort((a, b) => b.count - a.count);
    majorList.sort((a, b) => b.count - a.count);

    return (
      <DataSection title={"Platform Insights"}>
        <VStack align={"start"} w={"100%"} gap={[3, 5]}>
          <AthleteInsightsBlock athleteProfiles={athleteProfiles} />
          <PlatformInsightBlock title={"Universities"} data={universityList} />
          <PlatformInsightBlock title={"Sports"} data={sportList} />
          <PlatformInsightBlock title={"Majors"} data={majorList} />
        </VStack>
      </DataSection>
    );
  };

  const AthleteRecord = ({ profile }: AdminAthleteRowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    let bestInfo = <></>;
    if (profile.graduationYear >= new Date().getFullYear()) {
      bestInfo = (
        <Text>
          <strong>Expected Graduation Year: </strong>
          {profile.graduationYear}
        </Text>
      );
    }
    if (profile.professionalExperience > 0) {
      bestInfo = (
        <Text>
          <strong>Current Title: </strong>
          {profile.currentTitle}
        </Text>
      );
    }

    return (
      <Box
        onClick={() => setIsOpen(!isOpen)}
        flexDirection={"column"}
        // align={"flex-start"}
        w="100%"
        bg={"lightgrey"}
        py={4}
        px={3}
        rounded={"lg"}
      >
        <HStack justifyContent={"space-between"} alignItems={"center"}>
          <Flex flexDirection={["column", "row"]} justifyContent={"space-between"} w={"66%"}>
            <Heading size={"md"}>{profile.displayName || "Unknown Name"}</Heading>
            <Text>Profile Complete: {profile.percentageComplete}%</Text>
          </Flex>
          {profile.readyToWork ? <IoMdCheckmark color={"green"} size={24} /> : <IoMdClose color={"red"} size={24} />}
        </HStack>
        {isOpen && (
          <>
            <Divider pt={2} />
            <HStack w={"100%"} px={3} pt={2} rounded={"lg"} justifyContent={"space-between"} alignItems={"center"}>
              <Text>
                <strong>Location: </strong>
                {profile.currentLocation || "Unknown Location"}
              </Text>
              {bestInfo}
              <Button bg={"transparent"} onClick={() => alert("Opens athlete profile.")}>
                <Icon as={FaExternalLinkAlt} color={"brand.primary"} />
              </Button>
            </HStack>
          </>
        )}
      </Box>
    );
  };

  return (
    <Container maxWidth={"80ch"} p={[1]}>
      <VStack gap={5} align={"flex-start"} pt={3}>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Heading fontSize="4xl" fontWeight="bold" pt={3}>
            Admin Dashboard
          </Heading>
          <UpdateUserRole />
        </HStack>

        {universities && userInfo && (
          <FormControl>
            <FormLabel>Personal University Dashboard School</FormLabel>
            <ReactSelect
              options={universities}
              value={personalUniversity}
              getOptionLabel={(option) => option.universityName ?? ""}
              getOptionValue={(option) => option.id.toString()}
              onChange={(option) => changePrimaryUniversity(option)}
            />
          </FormControl>
        )}

        <PlatformInsights allProfiles={allProfiles} />
        <DataSection title={"Overview of Athletes"}>
          {allProfiles.map((profile) => (
            <AthleteRecord key={profile.id} profile={profile} />
          ))}
        </DataSection>
      </VStack>
    </Container>
  );
};
