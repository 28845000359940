import { Card, CardHeader, CardBody, VStack, Heading, HStack } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

import TextWithSmallLabel from "../TextWithSmallLabel";

interface AthletePreferredProps {
  profileData: UserProfileInfo;
}

const AthletePreferred: React.FC<AthletePreferredProps> = ({ profileData }) => {

  if (profileData.preferredLocation.length === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>Preferred Opportunities</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>Preferred Opportunities</Heading>
      </CardHeader>
      <CardBody paddingTop={2}>
        <VStack gap={5} w="full">
          <TextWithSmallLabel
            label="Work Environment"
            text={profileData?.preferredWorkEnvironment}
          />
          <TextWithSmallLabel
            label="Preferred Location(s)"
            text={profileData?.preferredLocation?.includes('Any') ? "Any Location" : profileData?.preferredLocation.join(", ")}
          />
          <TextWithSmallLabel
            label="Type of Work"
            text={profileData?.preferredEmployment}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthletePreferred;