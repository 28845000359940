import { Card, CardHeader, CardBody, Heading, Text, UnorderedList, ListItem, HStack } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";

interface AthleteAboutCardProps {
  profileData: UserProfileInfo;
  editMode?: boolean;
}

const AthleteAboutCard: React.FC<AthleteAboutCardProps> = ({ profileData, editMode = false }) => {

  if (profileData.aboutMe.length === 0) {
    return (
      <Card size="lg" w="full">
        <CardHeader>
          <HStack>
            <Heading size='md'>About</Heading>
          </HStack>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card size="lg" w="full">
      <CardHeader paddingBottom={2}>
        <Heading size='md'>About</Heading>
      </CardHeader>
      <CardBody paddingTop={2} paddingBottom={4}>
        <Text>{profileData.aboutMe}</Text>
      </CardBody>

      {(profileData?.highlightBullets.length > 0 || editMode) && (
        <>
          <CardHeader>
            <Heading size='md'>Highlights</Heading>
          </CardHeader>
          <CardBody paddingTop={0}>
            <UnorderedList spacing={1}>
              {profileData?.highlightBullets?.filter(item => item.selected).map((item) => (
                <ListItem key={item.id}>{item.contentToDisplay}</ListItem>
              ))}
            </UnorderedList>
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default AthleteAboutCard;