import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Card, Avatar, Heading, CardBody, VStack, HStack, Text, Button, Icon, Hide } from "@chakra-ui/react";
import { UserProfileSearchResult } from "@shared/models";
import { useNavigate } from "react-router-dom";

interface ProfileCardProps {
  profileData: UserProfileSearchResult;
  profileImage: string | undefined;
  universityName?: string | null;
}

const AthleteProfilePreviewCard: React.FC<ProfileCardProps> = ({ profileData, profileImage, universityName }) => {
  const navigate = useNavigate();

  return (
    <Card size={["sm", "lg"]} pt={[2, 0]} pb={[2, 0]} w="full" overflow={"hidden"}>
      <CardBody>
        <HStack gap={[3, 4]} alignItems="flex-start">
          <Avatar
            size={["lg", "lg"]}
            src={profileImage}
            bg="brand.primary"
            boxShadow='md'
            alignSelf="center"
          />

          <VStack gap={0} w="full" alignItems="flex-start">
            <Heading size={['sm', 'md']}>{profileData?.displayName}</Heading>

            <HStack w="full">
              <Heading size={['sm', 'md']} color={"brand.primary"}>
                {profileData?.athleticLevel} {profileData?.primarySport}
              </Heading>
            </HStack>
            {
              universityName && (
                <Text size={["sm", 'md']} color={"blackAlpha.500"} noOfLines={1}> at {universityName}</Text>
              )
            }
          </VStack>

          <Button
            borderRadius="full"
            pl={[0, 6]}
            pr={[1, 5]}
            variant="outline"
            colorScheme='gray'
            size='sm'
            fontWeight='normal'
            onClick={() => {
              navigate(`/athlete/profile/${profileData?.userId}`);
            }}
          >
            <Hide below="md">
              View Profile
            </Hide>
            <Icon ml={1} as={ArrowForwardIcon} transform="rotate(-45deg)" />
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default AthleteProfilePreviewCard;