import { Card, Box, Avatar, CardHeader, Heading, CardBody, VStack, HStack, Text } from "@chakra-ui/react";
import { UserProfileInfo } from "@shared/models";
import { useQueryClient } from "@tanstack/react-query";

import { useUniversity } from "../../hooks/useUniversity";
import { UploadButton } from "../UploadButton";

interface ProfileCardProps {
  profileData: UserProfileInfo;
  profileImage: { url: string } | null;
  editMode?: boolean;
}

const AthleteProfileCard: React.FC<ProfileCardProps> = ({ profileData, profileImage, editMode = false }) => {
  const queryClient = useQueryClient();

  const { university } = useUniversity(profileData?.universityId);

  return (
    <Card size="lg" w="full" overflow={"hidden"}>
      <Box
        bg="blackAlpha.300"
        w="full"
        h={["60px", 120, 120]}
        alignContent={"flex-end"}
        px={6}
      >
        <HStack justifyContent={"space-between"}>
          <Avatar
            size={["xl", "2xl"]}
            src={profileImage?.url}
            bg="brand.primary"
            top={[5, 12]}
            boxShadow='lg'
          />
          {editMode && (
            <VStack pt={[4, 15]} pr={[5, 8]}>
              <UploadButton
                label="Picture"
                category="profile-picture"
                includeImageCropper
                onUpload={() => queryClient.invalidateQueries({ queryKey: ['profileImage'], exact: true })}
                onDelete={() => queryClient.invalidateQueries({ queryKey: ['profileImage'], exact: true })}
              />
              <UploadButton label="Resume" category="resume" />
            </VStack>
          )}
        </HStack>
      </Box>
      <CardHeader paddingBottom={1} paddingTop={16}>
        <Heading size='xl'>{profileData?.displayName}</Heading>
      </CardHeader>
      <CardBody paddingTop={0}>
        <VStack gap={0} w="full" alignItems="flex-start">
          <HStack w="full">
            <Heading size='md' color={"brand.primary"}>
              {profileData?.athleticLevel} {profileData?.primarySport}
            </Heading>
            {profileData?.athleticAchievements?.slice(0, 3).map((item) => (
              <Text size='md' key={item.id} color={"blackAlpha.500"}>| {item.achievement}</Text>
            ))}
          </HStack>
          <Text size='sm' color={"blackAlpha.500"}>{university?.universityName}</Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default AthleteProfileCard;